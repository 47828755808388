<template>
  <div class="card card-custom">
    <modal name="add-category"
      adaptive: true
      :height="'auto'"
      :width="700"
      @closed="clearCategory"
    >
      <div class="add-category">
        <form @submit.prevent="save">
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">{{ nameModalUpdate }}</label>
            <div class="col-sm-8">
              <input
                  type="text"
                  class="form-control"
                  v-model="name"
              >
            </div>
            <div class="col-sm-2">
              <button class="btn btn-outline-success" type="submit">Сохранить</button>
            </div>
          </div>
        </form>
      </div>
    </modal>
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
            <h3 class="card-label">Морфемы</h3>
        </div>        
        <div class="card-toolbar">
            <a href="#"  @click="addCategory({
              nameCategory: 'morpheme', 
              url: 'morpheme', 
              nameModalUpdate: options.headings['morpheme']
            })">Добавить</a>
        </div>    
    </div>
    <div class="card-body">    
      <CategoryTable
          :columns="['morpheme', 'actions']"
          :options="options"
          :data="getDataCategory['morpheme']"
          :slot_="'morpheme'"
          @remove="delItem"
          @update="updateItem"
      /> 
    </div>
  </div>
</template>

<script>
  import CategoryTable from "@/components/table/CategoryTable";
  import EditBtn from "../../components/table/EditBtn"
  import DeleteBtn from "../../components/table/DeleteBtn"
  import { vueTableMixin } from "../mixins/vueTableMixin";
  import { categoryMixin } from "../mixins/categoryMixin";

  export default {
    name: 'Morphemes',
    mixins: [vueTableMixin, categoryMixin],
    components: {
      CategoryTable,
      EditBtn,
      DeleteBtn
    },
    data() {
      return {
        url: 'morpheme',     
        options: {
          headings: {
            morpheme: 'Морфема',
            actions: 'Действия'
          },       
        },
      }
    },
    
  }
</script>

<style>
  .add-button .VueTables__sortable {
    width: 33%;
  }

  .add-category {
    padding: 30px 30px 10px 30px;
  }

  .form-group input {
    border: 1px solid #cdcccc;
    border-radius: 5px;
  }

</style>
